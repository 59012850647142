/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $("#back-to-top").on('click', function(e) {
          e.preventDefault();
          window.scrollTo(0,0);
        });

        var isMobile = $(window).width() <= 768 ? true : false;

        $(".flexslider").flexslider({
          animation: "slide",
          itemWidth: (isMobile ? $(window).width() - 30 : $(window).width() / 2 - 30),
          animationLoop: true,
          minItems: (isMobile ? 1 : 2),
          maxItems: 2,
          slideshow: false,
          prevText: "",
          nextText: "",
          controlNav: false
        });
        $(".show-search").on('click', function(e) {
          e.preventDefault();
          setTimeout(function(){
            $(".search-field").focus();
          }, 1);
        });
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $featuredVideoOverlay = $('#featured-video-overlay');
        $featuredVideoIframe = $featuredVideoOverlay.find('iframe');

        $hero = $('.hero');
        $playIcon = $hero.find('.show-video');
        $img = $hero.find(".attachment-post-thumbnail");
        
        $(window).load(function() {
          $playIcon.css('left', $img.width() / 2 - 40);
          $playIcon.css('top', $img.height() / 2 - 40);
          $playIcon.addClass('show-fade-in');
        });

        var newWidth = $(window).width() - 100;
        var newHeight = (($featuredVideoIframe.height() / $featuredVideoIframe.width()) * newWidth);

        $featuredVideoIframe.width(newWidth);
        $featuredVideoIframe.height(newHeight);

        $featuredVideoOverlay.on('click', function() {
          $featuredVideoOverlay.collapse('toggle');
        });

        $(window).resize(function() {
          $playIcon.css('left', $img.width() / 2 - 40);
          $playIcon.css('top', $img.height() / 2 - 40);
          var newWidth = $(window).width() - 100;
          var newHeight = (($featuredVideoIframe.height() / $featuredVideoIframe.width()) * newWidth);

          $featuredVideoIframe.width(newWidth);
          $featuredVideoIframe.height(newHeight);

        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single Artist page
    'single_artist': {
      init: function() {

        $magnifyWrapper = $("#magnify-wrapper");
        var height = window.innerHeight ? window.innerHeight : $(window).height();
        $magnifyWrapper.height(height);
        
        var viewer = OpenSeadragon({
          id: "magnifier",
          maxZoomLevel: 10,
          visibilityRatio: 1,
          zoomInButton: 'zoom-in',
          zoomOutButton: 'zoom-out',
        });
        
        viewer.addHandler("zoom",function() {
          $("#zoom-range").val(viewer.viewport.getZoom());
        })      
        
        $("#zoom-range").on("input", function() {
          viewer.viewport.zoomTo($(this).val());
        });

        $(".magnify").on('click', function(e) {
          e.preventDefault();
          window.scrollTo(0,0);
          var img = $(this).data('full-size'); 
          viewer.addSimpleImage({
            url: img
          });          
          $magnifyWrapper.show();
        });

        $(".close-magnify").on('click', function(e) {
          viewer.viewport.goHome();     
          var oldImage = viewer.world.getItemAt(0);               
          if (oldImage != null) {
            viewer.world.removeItem(oldImage)
          }
          $magnifyWrapper.hide();
        });

        var idleTime = 0;
        var idleInterval = setInterval(function() {
          idleTime += 1;
          if (idleTime > 3) {
            $(".controls").fadeOut("slow");
          } 
        }, 1000);

        $("#magnify-wrapper").mousemove(function (e) {
          idleTime = 0;
          if (idleTime < 3) {
            $(".controls").fadeIn("slow");
          }
        });

      },
      finalize: function() {
        var tallestHeight = 0;        
        $('.entry-summary').each(function() {
          if ( $(this).height() > tallestHeight) {
            tallestHeight = $(this).height();
          }
        });
        $('.entry-summary').css('height', tallestHeight);
      }
    },
    'artists': {
      init: function() {
        var tallestHeight = 0;        
        $('.entry-summary').each(function() {
          if ( $(this).height() > tallestHeight) {
            tallestHeight = $(this).height();
          }
        });
        $('.entry-summary').css('height', tallestHeight);
      } 
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
